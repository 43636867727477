import React, { useState, useEffect } from 'react'
import { FaArchive } from 'react-icons/fa'
import 'react-multi-carousel/lib/styles.css'
import { getStrapiServices } from '../data/getStrapiServices'

const Services = () => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'services-groups?populate[services_cards][populate]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiServices(collectionData)
      setStrapiContent(data)
      console.log(data)
    }
    fetchData()
  }, [])

  return (
    <section id='services' className='services mx-auto px-4 sm:px-6 md:px-8 pb-6 bg-neutral-100 dark:bg-slate-800'>
      {strapiContent?.map(({ id, servicesTitle, servicesSubtitle, servicesCards }) => (
        <div key={id}>
          <div className='flex flex-col justify-center items-center pb-8'>
            <h1 className='pt-5 text-center text-dark dark:text-white text-2xl font-medium uppercase md:text-3xl'><span className='text-slate-700/70 dark:text-slate-300'>{servicesTitle}</span></h1>
            <p className='text-dark dark:text-white text-xl font-light uppercase md:text-2xl'>{servicesSubtitle}</p>
          </div>
          <div className='contain-services gap-6 mx-auto'>
            {servicesCards.map(({ id, icon = <FaArchive className='text-white' />, cardTitle, cardImage }) => (
              <div key={id} className='mx-4'>
                <div className='card-services w-[250px] mt-6 md:mt-0'>
                  <div className='card-services__image justify-items-center' style={{ backgroundImage: `url(${cardImage})` }} />
                  <div className='card-services__content dark:bg-slate-900/50'>
                    <span className='card-services__archive inline-flex items-center justify-center p-2 bg-amber-500 rounded-md shadow-lg'>
                      {icon}
                    </span>
                    <h3 className='card-services__content-text text-slate-700/70 capitalize dark:text-white mt-5 text-base font-medium tracking-tight'>{cardTitle}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  )
}

export default Services
