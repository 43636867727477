/* eslint-disable */
const token = 'f1760b112c368b7b11ea6054032b5da746c43bf0bbaade3503e630285f083f6c378fecc1b2398f981ab96a7c59e27873239a2011a0b1a14aa7655e382d3bb98472eef4ba044306a332442c4381b486848a4b98f23c509ed5aaf37136a7a731c81a21f7c862e097c038af4b8579f92b61f3a6a4fadfdda53bfc650fe131577250'
const STRAPI_URL = 'https://new-era.telluridedigitalworks.com/strapi'

export const getStrapiServices = async (collectionId) => {
  const collection = await (await fetch(`${STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        servicesTitle: attributes.services_title,
        servicesSubtitle: attributes.service_subtitle,
        galleryTitle: attributes.gallery_title,
        servicesCards: attributes.services_cards?.data.map(({attributes, id }) => ({
          id: id,
          cardTitle: attributes.card_title,
          cardImage: STRAPI_URL + attributes.card_image.data.attributes.url
        })),
        ourProjects: attributes.our_projects?.data.map(({attributes}) => ({
          original: STRAPI_URL + attributes.url,
          thumbnail: STRAPI_URL + attributes.url
        }))

      }
    }
  )

  return values
}
/* eslint-enable */
