import { Link } from 'react-scroll'

const Footer = () => {
  return (
    <footer className='p-4 bg-white shadow m-0 md:px-6 md:py-8 dark:bg-gray-800'>
      <div className='sm:flex text-center sm:items-center sm:justify-between'>
        <Link to='/' className='flex items-center mb-4 sm:mb-0'>
          <span className='self-center text-xl font-semibold whitespace-nowrap dark:text-white'>The New Era</span>
        </Link>

        <a href='tel:+19702501838' className='flex flex-row gap-2 items-center'>
          <p>Call Us </p>
          <svg className='w-5 h-5' fill='currentColor' version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100.000000 100.000000' preserveAspectRatio='xMidYMid meet'> <g transform='translate(0.000000,100.000000) scale(0.100000,-0.100000)'><path d='M174 912 c-21 -16 -56 -48 -78 -72 -36 -40 -38 -46 -32 -89 24 -186 336 -538 577 -651 36 -16 85 -33 111 -36 45 -6 48 -5 97 41 65 61 95 108 87 139 -4 16 -39 45 -107 90 -56 36 -108 66 -116 66 -7 0 -36 -11 -64 -25 -28 -14 -58 -23 -68 -20 -21 6 -213 197 -224 223 -6 13 -1 35 17 70 14 28 26 58 26 66 0 17 -115 196 -138 214 -24 19 -45 15 -88 -16z' /><path d='M500 883 c0 -17 9 -22 48 -27 66 -9 149 -50 201 -99 51 -49 98 -142 107 -209 5 -39 10 -48 27 -48 18 0 19 4 13 53 -9 69 -53 161 -105 219 -51 57 -161 114 -238 124 -49 6 -53 5 -53 -13z' /><path d='M500 773 c0 -14 13 -21 52 -31 101 -24 166 -89 190 -190 10 -39 17 -52 31 -52 16 0 18 5 12 43 -21 125 -117 220 -244 242 -36 6 -41 4 -41 -12z' /><path d='M500 661 c0 -13 10 -20 31 -25 40 -8 97 -65 105 -105 5 -21 12 -31 25 -31 24 0 24 23 0 75 -22 48 -44 69 -94 90 -48 20 -67 19 -67 -4z' /></g></svg>
        </a>
      </div>
      <hr className='my-2 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8' />
      <div className='sm:flex sm:justify-content-center sm:justify-between'>
        <span className='text-sm text-gray-500 sm:text-center dark:text-gray-400'>© {new Date().getFullYear()} <a href='https://telluridedigitalnews.com' className='hover:underline'>Telluride Digital Works™</a>. All Rights Reserved.
        </span>
        <div className='flex mt-4 space-x-6 sm:justify-center sm:mt-0'>
          <a href='https://www.facebook.com/profile.php?id=61557982393192&mibextid=WC7FNe&rdid=1LxMbXuihHwtRwao' className='w-10 text-gray-500 hover:text-gray-900 dark:hover:text-white'>
            <svg className='w-5 h-5' fill='currentColor' viewBox='0 0 24 24' aria-hidden='true'><path fillRule='evenodd' d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z' clipRule='evenodd' /></svg>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
