/* eslint-disable */
const token = 'f1760b112c368b7b11ea6054032b5da746c43bf0bbaade3503e630285f083f6c378fecc1b2398f981ab96a7c59e27873239a2011a0b1a14aa7655e382d3bb98472eef4ba044306a332442c4381b486848a4b98f23c509ed5aaf37136a7a731c81a21f7c862e097c038af4b8579f92b61f3a6a4fadfdda53bfc650fe131577250'
const STRAPI_URL = 'https://new-era.telluridedigitalworks.com/strapi'

export const getStrapiHero = async (collectionId) => {
  const collection = await (await fetch(`${STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        headerName: attributes.nav_title,
        headerLogo: STRAPI_URL + attributes.nav_logo.data.attributes.url,
        heroBackground: STRAPI_URL + attributes.hero_background.data.attributes.url,
        heroTitle: attributes.hero_title,
        heroButton: attributes.hero_button,
        sectionsOnPage: attributes.nav_items.sections_on_page.map(({section, id_section}) => ({
          id: Math.floor(Math.random() * 100),
          section: section,
          idSection: id_section
        }))
      }
    }
  )

  return values
}
/* eslint-enable */
